import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { Helmet } from 'react-helmet';
import styles from './Photos.module.css';

const Photos = () => {
  const shuffledPhotos = useMemo(() => {
    const photos = Array.from({ length: 48 }, (_, i) => `${i + 1}.jpeg`);
    for (let i = photos.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [photos[i], photos[j]] = [photos[j], photos[i]];
    }
    return photos;
  }, []);

  const breakpointColumns = {
    default: 3,
    1100: 3,
    800: 2,
    500: 1
  };

  const processedImages = useMemo(() => {
    return shuffledPhotos.map((photo) => {
      const imagePath = `/images/photos/${photo}`;
      
      return {
        key: photo,
        src: imagePath,
        alt: `Zerocam photo sample - Anti-AI photography ${photo.split('.')[0]}`
      };
    });
  }, [shuffledPhotos]);

  return (
    <>
      <Helmet>
        <title>Sergio's Photography Portfolio | Zerocam Samples</title>
        <meta name="description" content="Explore a collection of photos taken with Zerocam, the first Anti-AI camera." />
        <meta name="keywords" content="photography, Zerocam, anti-AI camera, photo portfolio, ios, android, process, zero" />
        <meta property="og:title" content="Sergio Rodriguez Rama Photography Portfolio" />
        <meta property="og:description" content="Photography samples taken with Zerocam anti-AI camera technology." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://rodriguezrama.com/photos" />
      </Helmet>

      <header>
        <nav className={styles.topNav} aria-label="Main Navigation">
          <div className={styles.navContent}>
            <Link to="/" className={`${styles.link} ${styles.navLink}`}>
              about me
            </Link>
            <h1 className={styles.navTitle}>sergio</h1>
            <Link to="/photos" className={`${styles.link} ${styles.navLink} ${styles.selected}`}>
              photos
            </Link>
          </div>
        </nav>
      </header>

      <main className={styles.container}>
        <h2 className={styles.visuallyHidden}>Photography Portfolio</h2>
        <p className={styles.visuallyHidden}>A collection of photographs taken with Zerocam anti-AI technology.</p>
        
        <Masonry
          breakpointCols={breakpointColumns}
          className={styles.myMasonryGrid}
          columnClassName={styles.myMasonryGridColumn}
        >
          {processedImages.map((image, index) => (
            <figure key={image.key} className={styles.imageContainer}>
            <img
              key={image.key}
                src={image.src}
                alt={image.alt}
                loading="lazy"
                decoding="async"
                width="400"
                height="300"
                className={styles.gridImage}
              />
              <figcaption className={styles.visuallyHidden}>Photo {index + 1} - Zerocam sample</figcaption>
            </figure>
          ))}
        </Masonry>
      </main>
    </>
  );
};

export default Photos; 