import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styles from './Main.module.css';
import Header from './Header';
import { FaLinkedin, FaXTwitter, FaAppStore, FaTelegram } from 'react-icons/fa6';
import { FaGooglePlay } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';  // Using Material icon for email
import { Link } from 'react-router-dom';  // Add this import

const shuffleArray = (array) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const images = shuffleArray(
  Array.from({ length: 15 }, (_, i) => `/images/${i + 1}.jpeg`)
);

export const Main = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className={styles.container}>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Sergio Rodriguez - iOS Developer</title>
        <meta name="title" content="Sergio Rodriguez - iOS Developer" />
        <meta name="description" content="iOS developer at TeleClinic and co-founder of Zerocam. Creator of mobile applications including LockSpeed and Memotico." />
        <meta name="keywords" content="iOS developer, mobile apps, Zerocam, Anti-AI camera, LockSpeed, Memotico, android, ios, app developer, mobile developer" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="Sergio Rodriguez - iOS Developer" />
        <meta property="og:description" content="iOS developer at TeleClinic and co-founder of Zerocam, the first Anti-AI camera." />
        <meta property="og:image" content="%PUBLIC_URL%/og-image.jpg" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:creator" content="@ramrodser" />
        <meta name="twitter:title" content="Sergio Rodriguez - iOS Developer" />
        <meta name="twitter:description" content="iOS developer at TeleClinic and co-founder of Zerocam, the first Anti-AI camera." />
        <meta name="twitter:image" content="%PUBLIC_URL%/og-image.jpg" />
        
        {/* Canonical URL */}
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      
      <Header title="sergio" />
      
      <nav className={styles.topNav}>
        <div className={styles.navContent}>
          <Link to="/" className={`${styles.link} ${styles.navLink} ${styles.selected}`}>
            about me
          </Link>
          <Link to="/photos" className={`${styles.link} ${styles.navLink}`}>
            photos
          </Link>
        </div>
      </nav>
      
      <div className={styles.content}>
        
        <div>
          iOS developer at{' '}
          <a href="https://apps.apple.com/app/id1094776229" className={styles.link}>TeleClinic</a>.
        </div>
        
        <div className={styles.spacer}></div>
        
        <div>
          Co-founder of{' '}
          <a href="https://apps.apple.com/app/id6483933438" className={styles.link}>Zerocam</a>, the first Anti-AI camera ideated and designed by{' '}
          <a href="https://x.com/novikoff" className={styles.link}>@novikoff</a>. Our apps include{' '}
          <a href="https://apps.apple.com/it/app/zerocam-mono/id6520394061" className={styles.link}>Zerocam Mono</a>{' '}
          for black & white photography and an{' '}
          <a href="https://play.google.com/store/apps/details?id=com.zerocam.app" className={styles.link}>Android version</a>.
        </div>
        
        <div className={styles.spacer}></div>
        
        <div>
          I also built{' '}
          <a href="https://apps.apple.com/app/id6478423217" className={styles.link}>LockSpeed</a>{' '}
          speedometer and{' '}
          <a href="https://apps.apple.com/app/id6465680456" className={styles.link}>Memotico</a>{' '}
          emoji widget game.
        </div>

        <div className={styles.spacer}></div>

        <div>
          Some Zerocam samples.
        </div>

      </div>

      <div className={styles.sliderContainer}>
        {currentImageIndex > 0 && (
          <button 
            className={`${styles.sliderButton} ${styles.prevButton}`}
            onClick={previousImage}
            aria-label="Previous image"
          />
        )}
        
        <div className={styles.sliderTrack}>
          {images.map((imagePath, index) => (
            <img 
              key={index}
              src={imagePath}
              alt={`Slide ${index + 1}`} 
              loading="lazy"
              decoding="async"
              style={{
                transform: `translateX(-${currentImageIndex * 100}%)`,
                transition: 'transform 0.3s ease-out'
              }}
              className={styles.sliderImage}
            />
          ))}
        </div>

        <button 
          className={`${styles.sliderButton} ${styles.nextButton}`}
          onClick={nextImage}
          aria-label="Next image"
        />
      </div>

      <div className={styles.socialLinks}>
        <a href="https://x.com/ramrodser" className={styles.socialLink}>
          <FaXTwitter size={20} />
        </a>
        <a href="https://apps.apple.com/developer/id1706587165" className={styles.socialLink}>
          <FaAppStore size={20} />
        </a>
        <a href="https://play.google.com/store/apps/dev?id=6618485665478378676" className={styles.socialLink}>
          <FaGooglePlay size={20} />
        </a>
        <a href="https://linkedin.com/in/serg-ios" className={styles.socialLink}>
          <FaLinkedin size={20} />
        </a>
        <a href="https://t.me/sergitomilano" className={styles.socialLink}>
          <FaTelegram size={20} />
        </a>
        <a href="mailto:sergio@rodriguezrama.com" className={styles.socialLink}>
          <MdEmail size={20} />
        </a>
      </div>
    </div>
  );
};